import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-707fd364"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = {
  key: 0,
  class: "toast-icon bi bi-check-circle"
}
const _hoisted_3 = {
  key: 1,
  class: "toast-icon bi bi-exclamation-circle"
}
const _hoisted_4 = { class: "heading" }
const _hoisted_5 = { class: "sub-heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.isOpen)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass('toast-main-segment' + ' ' + _ctx.position + ' ' + _ctx.type),
              role: "alert",
              "aria-live": "assertive"
            }, [
              _renderSlot(_ctx.$slots, "default", {}, () => [
                _createElementVNode("span", {
                  class: "float-end close-icon bi bi-x-circle",
                  onClick: _cache[0] || (_cache[0] = () => _ctx.$emit('update:isOpen', false))
                }),
                _createElementVNode("div", _hoisted_1, [
                  (_ctx.type === 'success')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_2))
                    : (_openBlock(), _createElementBlock("span", _hoisted_3)),
                  _createElementVNode("div", null, [
                    _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.title), 1),
                    _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.message), 1)
                  ])
                ])
              ], true)
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}