
import {
  defineComponent,
  watch,
  ref,
  computed,
  PropType
} from 'vue'
import {
  formatDate,
  formatUTCTime,
  generateTimeIntervals,
  getIsoDateFromUTC,
  convertEventScheduleToUTC,
  getTimeFromUTC
} from '@/views/Events/Events.utils'
import BsDialog from '@/components/BsDialog/BsDialog.vue'
import BsTextField from '@/components/BsTextField/BsTextField.vue'
import BsCheckbox from '@/components/BsCheckbox/BsCheckbox.vue'
import BsSelect from '@/components/BsSelect/BsSelect.vue'
import BsMultiSelect from '@/components/BsSelect/BsMultiSelect.vue'
import {PubSubFactory, PubSubs} from '@/mid-layer/Factories/PubSubFactory'
import locales from './AddWebinar.locales.en.json'
import { injectAddWebinarStore } from './AddWebinar.store'
import { EventModel, EventType, RepeatingOption } from '@/views/Events/Events.interface'
import { WebinarListUpdated } from '@/types/Topic'
import DefaultConstants from '@/utils/Constants.json'
import { MultiSelectOptionsModel } from '../BsSelect/BsMultiSelect.interfaces'
import useVuelidate, { ValidationArgs } from '@vuelidate/core'
import { helpers, required, requiredIf } from '@vuelidate/validators'
import { validateLink as ValidateLinkRegex} from '@/views/Events/Events.utils'

export default defineComponent({
  components: {
    BsDialog,
    BsTextField,
    BsSelect,
    BsCheckbox,
    BsMultiSelect
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    editDetails: {
      type: Object as PropType<EventModel>,
      default: () => null
    }
  },
  emits: ['update:isVisible'],
  setup(props, {emit}) {
    const state = ref<EventModel>(getInitialState())
    const isSaving = ref<boolean>(false)
    const editMode = ref<boolean>(!!props.editDetails)
    const timeIntervalOptions = ref(generateTimeIntervals())
    const jurisdictions = DefaultConstants.Jurisdictions as MultiSelectOptionsModel[]
    const products = DefaultConstants.Products.filter(product => product.grouping !== DefaultConstants.LSATGrouping) as MultiSelectOptionsModel[]
    const nextFiveYears = computed(() => {
      const currentDate = new Date()
      const list: number[] = []
      for (let i = currentDate.getFullYear(); i < currentDate.getFullYear() + 6; i++) {
        list.push(i)
      }
      return list
    })
    const store = injectAddWebinarStore()

    const pubSub = PubSubFactory.GetPubSub(PubSubs.PubSubJS)

    const mustBeGreaterThanStartTime = (time: string) => time > state.value.startTime

    const validateLink = helpers.withMessage(
      locales.InvalidURL, 
      (value: string) => !value || ValidateLinkRegex.test(value)
    )

    const rules: ValidationArgs = {
      description: {required},
      startDate: {required},
      startTime: {required},
      endTime: {required, mustBeGreaterThanStartTime},
      timeZone: {required},
      meetingLink: {required, validateLink},
      recordingLink: { validateLink},
      jurisdictions: { required },
      products: {required},
      year: { required: requiredIf(() => !!state.value.period ) },
      period: { required: requiredIf(() => !!state.value.year ) }
    }

    const v$ = useVuelidate(rules, state)

    function getInitialState() {
      return {
        eventType: EventType.Webinar,
        coachId: EventType.Webinar,
        repeating: RepeatingOption.DoesNotRepeat,
        products: [] as string[],
        jurisdictions: [] as string[],
        tags: [] as string[],
        attendees: [] as string[],
        description: '',
        meetingLink: '',
        recordingLink: '',
        isHidden: false,
        year: undefined,
        period: undefined
      } as EventModel
    }

    watch(
        () => props.isVisible,
        () => {
          editMode.value = !!props.editDetails
          if (editMode.value) {
            state.value = props.editDetails

            state.value.startDate = getIsoDateFromUTC(
              props.editDetails.startDate,
              props.editDetails.startTime,
              props.editDetails.timeZone
            )
            state.value.endDate = state.value.startDate
            state.value.startTime = getTimeFromUTC(
              props.editDetails.startDate,
              props.editDetails.startTime,
              props.editDetails.timeZone
            )
            state.value.endTime = getTimeFromUTC(
              props.editDetails.startDate,
              props.editDetails.endTime,
              props.editDetails.timeZone
            )
          } else {
            state.value = getInitialState()
          }
        }
    )

    function closeModal() {
      state.value = getInitialState()
      v$.value.$reset()
      emit('update:isVisible', false)
    }

    async function onSubmit() {
      v$.value.$touch()

      if (v$.value.$invalid) {
        return
      }

      try {
        isSaving.value = true

        const event = JSON.parse(JSON.stringify(state.value))

        const startDateTime = convertEventScheduleToUTC(
          event.startDate,
          event.startTime,
          event.timeZone
        )
        const endDateTime = convertEventScheduleToUTC(
          event.startDate,
          event.endTime,
          event.timeZone
        )
        event.startDate = startDateTime.toISODate()
        event.endDate = endDateTime.toISODate()
        event.startTime = formatUTCTime(startDateTime)
        event.endTime = formatUTCTime(endDateTime)
        event.maxAttendees ??= 1

        if (editMode.value) {
          await store.actions.editWebinar(event.id ?? '', event)
        } else {
          await store.actions.addWebinar(event)
        }
        closeModal()
        pubSub.Publish(WebinarListUpdated)
      } finally {
        isSaving.value = false
      }
    }
    
    const isEbar = computed(() => {
      return state.value.products.length > 0 &&
        state.value.products.every(productValue => DefaultConstants.EbarProductIds.includes(productValue))
    })

    const computeVisibleModal = computed({
      get: () => props.isVisible,
      set: (val) => emit('update:isVisible', val)
    })

    return {
      state,
      onSubmit,
      closeModal,
      locales,
      isSaving,
      isEbar,
      computeVisibleModal,
      editMode,
      formatDate,
      DefaultConstants,
      timeIntervalOptions,
      jurisdictions,
      products,
      v$,
      nextFiveYears
    }
  }
})
