import { IStore } from '@/interfaces/common/StoreEntities'
import { Coach } from '@/types/Coach'
export interface AddEventModel {
  eventType: EventType,
  newEvent: EventModel
}

export interface EventModel {
  products: string[];
  jurisdictions: string[];
  coachId: string;
  description: string;
  schoolId: string;
  meetingLink: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  timeZone: string;
  repeating: number;
  attendees: string[];
  maxAttendees: number;
  tags?:string[];
  eTag?: string;
  id?:string;
  isHidden:boolean;  
  datesChanges?: DatesChanges,
  eventType: EventType,
  title?: string;
  salesforceClassroomId: string;
  recordingLink: string;
  documentFileNames: string[];
  year?: number;
  period?: number;
}

export enum EventType { 
  CoachingSession = 'CoachingSession',
  Webinar = 'Webinar'
}

export interface DatesChanges{
  added:string[],  
  removed:string[]
}

export interface AddEventFormValues extends EventModel {
  email: string;
}

export enum RepeatingOption {
  DoesNotRepeat = 1,
  Weekly = 2,
  Biweekly = 3,
  Custom = 4
}

export interface LabelValue {
  label: string;
  value: string;
}

export interface Webinar {
  id: string
  title: string
  dateTime: string
  link: string
  hidden: 'Y' | 'N'
  event: EventModel
}

export interface SchoolAccount {
  id: string;
  name: string;
}

export interface ProductList {
  productSettings: ProductSettings[]
}

export interface ProductSettings {
  id: string,
  maxAttendees: number,
  grouping: string,
  name: string
}

export interface TagList {
  courseTags: CourseTag[]
}

export interface CourseTag {
  title: string,
  abbreviation: string,
  color: string,
  description: string,
  isActive: boolean
}

export interface IEventsState {
  events: (EventModel)[];
  products: LabelValue[];
  jurisdictions: LabelValue[];
  schools: SchoolAccount[];
  productSettings: ProductSettings[];
  courseTags: CourseTag[];
}
export interface IEventsActions {
  addEvent: (val: AddEventModel, documentFiles: File[], holdLoadingScreen?: boolean) => void;
  editEvent: (
    id: string,
    val: EventModel,
    newDocumentFiles: File[],
    removedDocumentFileNames: string[],
    headers?: { [name: string]: string },
    holdLoadingScreen?: boolean
  ) => void;
  deleteEvent: (id: string) => void;
  getEvents: () => void;
  getSchools: () => void;
  filterProductsAndJurisdictions: (coachId: string, coaches: Coach[]) => void;
  getProducts:() => void;
  updateProducts:(val: ProductSettings[]) => void;
  getTags:() => void;
  updateTags:(val: CourseTag[]) => void;
  getEventDocument:(eventId: string, documentName: string) => void;
}

export interface CalendarEvent{
  start: string, // Required.
  end: string, // Required.  
  title?: string, // Optional.
  content?: string, // Optional.
  class?: string, // Optional - space-separated css classes.
  background?: boolean // Optional. (Event type not CSS property)
  split?: number|string // Optional.
  allDay?: boolean // Optional.
  deletable?: boolean // optional - force undeletable when events are editable.
  resizable?: boolean // optional - force unresizable when events are editable.
}

export type IEventsStore = IStore<IEventsState, IEventsActions>;
